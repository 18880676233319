import ArrowForward from '@/app/_components/Icon/ArrowForward'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import Link from 'next/link'

export function ShowAllConversations() {
  const [, setState] = useMessengerState()

  return (
    <div className="z-10 flex h-62 shrink-0 flex-row items-center justify-between border-t border-tertiary bg-white px-24">
      <p className="c-b4">모두 읽음처리 하기</p>
      {/* <Button type="button" variant="outlined" color="black" size="sm" asChild> */}
      <Link
        target={'_blank'}
        href={'/messenger'}
        onClick={() => {
          setState((draft) => {
            draft.open = false
          })
        }}>
        <div className={'c-b4 flex items-center gap-6 text-french-bleu'}>
          전체 보기
          <ArrowForward className="!h-16 !w-16" fill="#3366B6" />
        </div>
      </Link>
      {/* </Button> */}
    </div>
  )
}
