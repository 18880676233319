import { useI18n } from '@/locales/client'
import Image from 'next/image'
import Link from 'next/link'

interface IUser {
  name: string
  imageURL: string
  email: string
  isAdmin?: boolean
}

export default function HeaderUserMenu({ user }: { user: IUser }) {
  const { name, imageURL, email, isAdmin } = user
  const handleSignOut = () => {
    window.location.href = '/api/auth/logout'
  }
  const t = useI18n()

  return (
    <div className="absolute right-0 top-65 z-50 w-291 animate-slideDownAndFade rounded-16 bg-white p-12 shadow-card-mobile xl:shadow-card-web">
      <div className="flex items-center gap-8 p-16">
        <div className={`size-39 overflow-hidden rounded-full`}>
          <Link href={'/dashboard/me'}>
            <Image
              className={'rounded-full'}
              src={imageURL}
              alt="img"
              width={39}
              height={39}
              objectFit="cover"
            />
          </Link>
        </div>
        <div>
          <div className="c-h7">{name}</div>
          <div className="c-b4 text-french-bleu">{email}</div>
        </div>
      </div>
      <div className="my-8 w-full border-t-1 border-[#E2E8F0]" />
      <div className="flex flex-col">
        {isAdmin && (
          <div className="c-b3 rounded-lg px-16 py-8 hover:bg-french-bleu/10">
            <Link target="_blank" href="/admin">
              Admin
            </Link>
          </div>
        )}
        <div className="c-b3 rounded-lg px-16 py-8 hover:bg-french-bleu/10">
          <Link href="/dashboard/me">{t('common.myBoothLabel')}</Link>
        </div>
        <div className="c-b3 rounded-lg px-16 py-8 hover:bg-french-bleu/10">
          <Link href="/change-password">{t('common.changePwd')}</Link>
        </div>
        <button
          type="button"
          className="c-b3 cursor-pointer rounded-lg px-16 py-8 text-left text-txt-secondary hover:bg-french-bleu/10"
          onClick={handleSignOut}>
          {t('common.signOutLabel')}
        </button>
      </div>
    </div>
  )
}
